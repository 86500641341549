import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { StepFormBuilder } from "../../../components";
import { createTreaty } from "_graphql/mutation/treaty";
import {
  CreateTreaty,
  CreateTreatyVariables,
} from "_graphql/mutation/__generated__/CreateTreaty";
import { createTreatyFormItems } from "./form-steps";
import { v4 } from "uuid";
import _ from "lodash";
import { FC, useEffect } from "react";
import { Treaty_treaty } from "_graphql/queries/__generated__/Treaty";
import { updateTreaty } from "_graphql/mutation/offers";
import {
  UpdateTreaty,
  UpdateTreatyVariables,
} from "_graphql/mutation/__generated__/UpdateTreaty";

type Props = {
  setClose?: (value: boolean) => void;
  treaty: Treaty_treaty | null | undefined;
};

const TreatyFormContainer: FC<Props> = ({ setClose, treaty }) => {
  const [initiateCreate] = useMutation<CreateTreaty, CreateTreatyVariables>(
    createTreaty,
    {
      refetchQueries: ["GetTreaties"],
    }
  );
  const [initiateUpdate] = useMutation<UpdateTreaty, UpdateTreatyVariables>(
    updateTreaty,
    {
      refetchQueries: ["GetTreaties"],
    }
  );
  const form = useFormik<{
    treaty: any;
    deductions: any;
    surplus: {
      surpluses: any[];
      currency: string;
      profit_commission: string;
      estimated_retained_premium_income: string;
      reinsurers_management_expense: string;
      overall_gross_premium: string;
      portfolio_entry: {
        withdrawal_percentage: string;
        withdrawal_loss_percentage: string;
        assumption_percentage: string;
        assumption_loss_percentage: string;
      };
    };
    treaty_details?: any;
  }>({
    initialValues: {
      treaty: {
        treaty_reference: "",
        treaty_type: "",
        treaty_program: "",
        kek_reference: "",
        order_hereon: "",
      },
      deductions: {
        layers: [],
        ergnrpi: "",
        min_rate: "",
        max_rate: "",
        numerator: "",
        denominator: "",
        currency: "",
      },
      surplus: {
        surpluses: [],
        currency: "",
        profit_commission: "",
        estimated_retained_premium_income: "",
        reinsurers_management_expense: "",
        overall_gross_premium: "",
        portfolio_entry: {
          withdrawal_percentage: "",
          withdrawal_loss_percentage: "",
          assumption_percentage: "",
          assumption_loss_percentage: "",
        },
      },
    },
    onSubmit: async (values) => {
      if (treaty) {
        await initiateUpdate({
          variables: {
            treaty: prepareTreaty(values),
            treatyId: treaty.treaty_id,
          },
        })
          .then((res) => {
            if (res.data?.updateTreaty) {
              toast.success("Treaty updated successfully");
              setClose?.(false);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        await initiateCreate({
          variables: {
            treaty: prepareTreaty(values),
          },
        }).then((res) => {
          if (res.data?.createTreaty) {
            toast.success("Treaty created successfully");
            setClose?.(false);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (treaty) {
      const limitLayers: any[] = JSON.parse(treaty?.layer_limit || "[]") || [];
      if (limitLayers.length > 0) {
        limitLayers.shift()
      }
      // console.log("LIMIT LAYERS", limitLayers);
      form.setValues({
        treaty: {
          treaty_reference: treaty?.treaty_reference,
          treaty_type: treaty?.treaty_program?.treaty_type,
          treaty_program: treaty?.treaty_program,
          kek_reference: treaty?.kek_reference,
          order_hereon: treaty?.order_hereon,
          insurer: treaty?.insurer,
          treaty_program_deduction: treaty?.treaty_deduction,
        },
        surplus: {
          surpluses: limitLayers,
          currency: treaty?.currency || "",
          profit_commission:
            treaty?.treaty_proportional_detail?.profit_commission?.toString() ||
            "",
          estimated_retained_premium_income:
            treaty?.treaty_proportional_detail?.ernpi?.toString() || "",
          reinsurers_management_expense:
            treaty?.treaty_proportional_detail?.re_mgmt_expense?.toString() ||
            "",
          overall_gross_premium: "",
          portfolio_entry: JSON.parse(
            treaty?.treaty_proportional_detail?.portfolio_entry || "{}"
          ),
        },
        deductions: {
          ergnrpi: treaty?.treaty_np_detail?.egrnpi,
          min_rate: _.first(limitLayers)?.max_rate,
          layers: limitLayers.map((el) => ({
            ...el,
            installments: el.installment_type,
          })),
          max_rate: _.first(limitLayers)?.max_rate,
          numerator: _.first(limitLayers)?.numerator,
          denominator: _.first(limitLayers)?.denominator,
          currency: treaty?.currency,
        },
      });
    }
  }, [treaty]);

  return (
    <StepFormBuilder
      formSteps={getFormSteps(form.values.treaty?.treaty_type)}
      form={form}
      setOpen={setClose}
    />
  );
};

export default TreatyFormContainer;

const prepareTreaty = (values: any) => {

  const surpluses: any[] = values.surplus?.surpluses?.map((el: any) => ({
    limit: el.limit,
    commission: el.commission,
    profit_commission: el.profit_commission,
    ernpi: el.installments,
    outgoing_payment_staus: "UNPAID",
    ...el,
  }));

  const deduction: any = values?.treaty?.treaty_program_deduction;
  console.log("Deduction", deduction);

  return _.omitBy(
    {
      currency: values.surplus?.currency || values.deductions?.currency,
      treaty_reference: values.treaty?.treaty_reference,
      insurersinsurer_id: values.treaty?.insurer?.insurer_id,
      kek_reference: values.treaty?.kek_reference,
      layer_limit:
        values.treaty?.treaty_type === "PROPORTIONAL"
          ? JSON.stringify(
            [{
              surpulus_uuid: v4(),
              commission: deduction?.commission,
              outgoing_payment_staus: "UNPAID",
            }, ...surpluses]
          )
          : JSON.stringify(
            values.deductions?.layers.map((el: any, index: number) => ({
              uuid: el.uuid || v4(),
              limit: el.limit,
              deductible: el.deductible,
              m_and_d_premium: el.m_and_d_premium,
              // egrnpi: el.installments,
              outgoing_payment_staus: "UNPAID",
              installment_type: el.installments,
              ...el,
              ...(index === 0
                ? {
                  min_rate: values.deductions?.min_rate,
                  max_rate: values.deductions.max_rate,
                  numerator: values.deductions.numerator,
                  denominator: values.deductions.denominator,
                  adjust_rate: el.adjust_rate,
                }
                : {
                  adjust_rate: el.adjust_rate,
                }),
            }))
          ),
      order_hereon: values.treaty?.order_hereon,
      m_and_d_premium: values.deductions?.m_and_d_premium,
      treaty_associate_deductionstreaty_associate_deduction_id:
        values.treaty?.treaty_program_deduction?.treaty_associate_deduction_id,
      treaty_programstreaty_program_id:
        values.treaty?.treaty_program?.treaty_program_id,
      treaty_details: values?.treaty?.treaty_program?.treaty_details,
      treaty_extended_details: {
        [values.treaty?.treaty_type === "PROPORTIONAL"
          ? "proportional_detail"
          : "np_detail"]:
          values.treaty?.treaty_type === "PROPORTIONAL"
            ? {
              treaty_id: values.treaty?.treaty_program?.treaty_program_id,
              profit_commission: values.surplus?.profit_commission as any,
              re_mgmt_expense: values.surplus?.reinsurers_management_expense as any,
              ernpi: values.surplus?.estimated_retained_premium_income as any,
              portfolio_entry: values.surplus.portfolio_entry as any,
              overall_gross_premium: values.surplus
                .overall_gross_premium as any,
            }
            : {
              egrnpi: values.deductions?.ergnrpi,
            },
        type: values.treaty?.treaty_type,
      },
    },
    _.isNil
  );
};

const getFormSteps = (treatyType: string) => {
  switch (treatyType) {
    case "NONPROPORTIONAL":
      return createTreatyFormItems.filter((el) => el.accessor !== "surplus");
    case "PROPORTIONAL":
      return createTreatyFormItems.filter((el) => el.accessor !== "deductions");
    default:
      return createTreatyFormItems
        .filter((el) => el.accessor !== "surplus")
        .filter((el) => el.accessor !== "deductions");
  }
};
