import { BrokerApprovalState } from "_graphql/__generated__/globalTypes-place-it";
import { SelectInput, TextArea } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";

export interface BrokerApprovalProps {
  form: FormikProps<any>;
}

const BrokerApproval: FC<BrokerApprovalProps> = ({
  form,
}) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <div className="grid grid-cols-2 gap-2">
          <div className="col-span-3">
            <div className="">
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div className="col-span-3">
                  <SelectInput
                    options={[
                      { label: "Active", value: BrokerApprovalState.ACTIVE },
                      { label: "Inactive", value: BrokerApprovalState.DEACTIVATE },
                      { label: "Probation", value: BrokerApprovalState.PROBATION },
                    ]}
                    id={"approval_state"}
                    label="Approval State"
                    required
                    placeholder="Select approval state"
                    {...form}
                  />
                </div>
                {(form.values.approval_state === BrokerApprovalState.DEACTIVATE || form.values.approval_state === BrokerApprovalState.PROBATION) && (
                  <div className="col-span-3 row-span-3">
                    <TextArea
                      id="reason"
                      label="Reason"
                      placeholder="Enter your comment here..."
                      {...form}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerApproval;
