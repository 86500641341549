import { wrapClick } from 'utils';
import { AutoFacparticipantView, BrokerView, Modal, ReInsurerView } from 'components';
import { useSearch, useNavigate } from 'react-location';
import { LocationGenerics } from 'router/location';
import { Action } from 'components/buttons/action-button';
import { useReInsurer } from 'hooks/data/use-re-insurers';
import { useBroker } from 'hooks/data/use-brokers';
import { useAutoFacConfigurationParticipant } from 'hooks/data/use-aut-fac';


export default function ViewContainer({ open, setOpen }: { open: boolean; setOpen: (val: boolean) => void }) {

    const searchParams = useSearch<LocationGenerics>();
    const navigate = useNavigate<LocationGenerics>();
    const { participant, loading } = useAutoFacConfigurationParticipant({
        autoFacParticipantId: searchParams?.id || ""
    });
    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };
    const expandItem = () => {
        navigate({
            to: `./${searchParams.id}`,
        });
    };

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            title="Auto Fac Participant"
            description="Details of participent are shown below"
        >
            <AutoFacparticipantView
                particpant={participant}
            />
        </Modal>
    )
}