import { gql } from "@apollo/client";

export const getOffers = gql`
  query Offers($args: OfferFilters) {
  count: getOffersCount(args: $args)
  rows: getOffers(args: $args) {
    offer_id
    rate
    commission
    commission_amount
    brokerage
    facultative_offer
    expiry_status
    sum_insured
    fac_sum_insured
    offer_from
    premium
    fac_premium
    offer_status
    payment_status
    claim_status
    offer_detail {
      offer_detail_id
      offersoffer_id
      policy_number
      insured_by
      period_of_insurance_to
      period_of_insurance_from
      currency
      offer_comment
      offer_details
    }
    offer_claims {
      offer_claim_id
      claim_amount
      claim_comment
      claim_date
      created_at
      offer_claim_participants {
        offer_claimsoffer_claim_id
        offer_claim_participant_id
        reinsurer_id
        offer_participantsoffer_participant_id
        offer_participant_percentage
        re_company_name
        claim_share
        created_at
      }
    }
    offer_participant {
      offer_participant_id
      offer_participant_percentage
      offer_amount
      participant_fac_premium
      participant_fac_sum_insured
      offer_extra_charges {
        nic_levy
        agreed_brokerage_percentage
        withholding_tax
        agreed_commission
        agreed_commission_amount
        brokerage_amount
        nic_levy_amount
        withholding_tax_amount
      }
      offer_deduction_charge {
        nic_levy_paid
        withholding_tax_paid
        brokerage_amount_paid
        commission_taken
      }
      reinsurer {
        reinsurer_id
        re_company_name
        re_company_email
        reinsurer_address {
          country
        }
      }
    }
    insurer {
      insurer_id
      insurer_company_name
      insurer_company_email
      insurer_company_website
      insurer_address {
        suburb
        region
        country
      }
    }
    classofbusiness {
      class_of_business_id
      business_name
      business_details
      created_at
    }
    created_at
    employee {
      employee_id
      employee_first_name
      employee_last_name
      employee_phonenumber
      employee_email
      emp_abbrv
    }
    place_it_id
  }
}
`;

export const getReInsurerOffers = gql`
  query GetReinsurerOffers($args: OfferFiltersReinsurer, $reinsurer_id: ID) {
    getReinsurerOffersCount(reinsurer_id: $reinsurer_id)
    getReinsurerOffers(args: $args) {
      offersoffer_id
      offer_participant_id
      offer_detail {
        currency
        policy_number
      }
      offer_participant_percentage
      participant_fac_premium
      offer_extra_charges {
        offer_extra_charge_id
        withholding_tax
        agreed_commission
        nic_levy
        agreed_brokerage_percentage
      }
      offer_participant_payment {
        offer_participant_payment_id
        offer_payment_amount
        paid_details
        offer_participant_payment_comment
        payment_status
        created_at
        updated_at
        offer_deduction_charge {
          offer_deduction_payment_id
          nic_levy_paid
          withholding_tax_paid
          brokerage_amount_paid
          commission_taken
        }
      }
      reinsurer_offers_only {
        offer_id
        rate
        premium
        brokerage
        fac_premium
        facultative_offer
        commission
        fac_sum_insured
        sum_insured
        created_at
        offer_status
        payment_status
        classofbusiness {
          business_name
          class_of_business_id
          business_details
        }
        insurer {
          insurer_company_name
        }
        offer_detail {
          insured_by
          currency
          policy_number
          period_of_insurance_from
          period_of_insurance_to
          offer_comment
          offer_details
        }
      }
    }
  }
`;

export const getOfferParticipant = gql`
  query GetOfferParticipant($participantId: ID) {
    getOfferParticipant(participant_id: $participantId) {
      offersoffer_id
      offer_participant_id
      offer_participant_percentage
      offer_amount
      participant_fac_premium
      participant_fac_sum_insured
      created_at
      reinsurer {
        reinsurer_id
        re_abbrv
        re_company_email
        re_company_name
        reinsurer_address {
          country
        }
      }
    }
  }
`;

export const getEndorsementParticipant = gql`
query GetEndorsementParticipant($offerEndParticipantId: ID) {
  getEndorsementParticipant(offer_end_participant_id: $offerEndParticipantId) {
    offer_end_participant_id
    offer_end_participant_percentage
    offer_end_amount
    offer_end_participant_fac_premium
    offer_end_participant_fac_sum_insured
    offer_endorsement_extra_charges {
      end_nic_levy
      end_nic_levy_amount
      end_withholding_tax
      end_agreed_brokerage_percentage
      end_brokerage_amount
      end_withholding_tax_amount
      end_agreed_commission
      end_agreed_commission_amount
    }
    reinsurer {
      reinsurer_id
      re_company_name
    }
  }
}

`;

export const getOfferAssociates = gql`
  query GetOfferAssociates($offerId: ID) {
    getOfferAssociates(offer_id: $offerId) {
      offer_to_associate_id
      message_content
      message_attachment
      sent_status
      created_at
      offer_participants {
        offersoffer_id
        offer_participant_id
        offer_participant_percentage
        offer_amount
        participant_fac_premium
        participant_fac_sum_insured
        created_at
      }
      reinsurer_representative {
        reinsurer_representative_id
        reinsurersreinsurer_id
        rep_first_name
        rep_last_name
        rep_primary_phonenumber
        rep_secondary_phonenumber
        rep_email
        position
        reinsurer {
          re_company_email
          re_company_name
        }
      }
    }
  }
`;


export const getOfferFleet = gql`
  query OfferFleet($policy_number: String, $skip: Int) {
    getOfferFleet(policy_number: $policy_number, skip: $skip) {
      offer_id
      vehile_reg_no
    }
  }`;

export const getOffer = gql`
  query Offer($offer_id: ID!) {
  findSingleOffer(offer_id: $offer_id) {
    offer_id
    rate
    commission
    commission_amount
    brokerage
    facultative_offer
    sum_insured
    fac_sum_insured
    premium
    fac_premium
    offer_status
    payment_status
    claim_status
    offer_detail {
      offer_detail_id
      offersoffer_id
      policy_number
      insured_by
      period_of_insurance_to
      period_of_insurance_from
      currency
      offer_comment
      offer_details
    }
    offer_participant {
      offer_participant_id
      offer_participant_percentage
      offer_amount
      reinsurer {
        reinsurer_id
        re_company_name
        re_company_email
      }
    }

    classofbusiness {
      class_of_business_id
      business_name
      business_details
      business_category
      created_at
    }

    insurer {
      insurer_company_name
      insurer_company_email
      insurer_company_website
      insurer_id
    }

    offer_associates {
      offer_to_associate_id
      sent_status
      reinsurer_representative {
        rep_email
        rep_first_name
        rep_last_name
        rep_primary_phonenumber
        rep_secondary_phonenumber
        reinsurer {
          re_company_name
          re_company_email
        }
      }
    }
    offer_claims {
      claim_amount
      claim_comment
      claim_date
      created_at
      offer_claim_id
      offer_claim_participants {
        claim_share
        created_at
        offer_claim_participant_id
        offer_claimsoffer_claim_id
        offer_participant_percentage
        offer_participantsoffer_participant_id
        re_company_name
        reinsurer_id
      }
    }
    reinsurer_offer_extra_charge {
      offer_extra_charge_id
      nic_levy
      nic_levy_amount
      withholding_tax
      withholding_tax_amount
      agreed_brokerage_percentage
      brokerage_amount
      agreed_commission
      agreed_commission_amount
      created_at
    }
  }
}

`;

export const getOfferClaims = gql`
  query GetOfferClaims($args: ClaimsFilter) {
    getOfferClaims(args: $args) {
      offer_claim_id
      claim_amount
      claim_comment
      claim_date
      currency
      rate
      offer {
        offer_detail {
          currency
        }
      }
    }
  }
`;
export const getOfferClaimShares = gql`
 query GetClaimShares($offerClaimId: ID) {
  getClaimShares(offer_claim_id: $offerClaimId) {
    offer_claim_participant_id
    claim_share
    created_at
    offer_claimsoffer_claim_id
    reinsurer_id
    re_company_name
    offer_participantsoffer_participant_id
    offer_participant_percentage
    offer_claim {
      offer_claim_id
      claim_amount
      claim_date
      created_at
      claim_comment
    }
    offer_participant {
      offersoffer_id
      offer_participant_id
      offer_participant_percentage
      offer_amount
      participant_fac_premium
      participant_fac_sum_insured
      created_at
      reinsurer {
        re_company_name
        reinsurer_id
      }
      offer_detail {
        currency
      }
    }
  }
}

`;
export const getOfferClaimShare = gql`
  query GetClaimShare($offerClaimParticipantId: ID!) {
    getClaimShare(offer_claim_participant_id: $offerClaimParticipantId) {
      offer_claim_participant_id
      claim_share
      created_at
      offer_claimsoffer_claim_id
      reinsurer_id
      re_company_name
      offer_participantsoffer_participant_id
      offer_participant_percentage
    }
  }
`;


export const getOfferClaimParticipantPayments = gql`
query GetOfferClaimParticipantPayments($offerClaimParticipantId: ID!) {
  getOfferClaimParticipantPayments(
    offer_claim_participant_id: $offerClaimParticipantId
  ) {
    offer_claim_participant_payment_id
    claim_share_payment_amount
    payment_details
    payment_comment
    payment_date
    delete_status
    created_at
    updated_at
    offer_claim_participant {
      offer_claim_participant_id
      claim_share
      payment_status
      delete_status
      created_at
      updated_at
      offer_claim {
        offer_claim_id
        claim_amount
        claim_comment
        claim_date
        currency
        rate
      }
      offer_participant {
        offer_participantsoffer_participant_id
      }
    }
  }
}
`;
