import { useQuery } from "@apollo/client";
import { placeItClient } from "_graphql";
import { GetUnderwriters, GetUnderwritersVariables } from "_graphql/queries/__generated__/GetUnderwriters";
import { getUnderwriters } from "_graphql/queries/underwriters.place-it";


export const useUnderWriters = (variables: GetUnderwritersVariables) => {
    const { data, ...rest } = useQuery<GetUnderwriters, GetUnderwritersVariables>(getUnderwriters, {
        variables,
        client: placeItClient,
        fetchPolicy: "network-only",
        pollInterval: 1000 * 30
    });

    return { underwriters: data?.underwriters?.data || [], length: data?.underwriters?.paginatorInfo?.total || 0, ...rest };
}