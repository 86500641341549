import { Offers_rows } from "_graphql/queries/__generated__/Offers";
import {
  ActionButton,
  OfficeHeader,
  Paginator,
  RegularInsurerPicker,
  RegularSelectInput,
  TableComponent,
  TableLoader,
} from "components";
import { useOffers } from "hooks/data/use-offers";
import numeral from "numeral";
import { FC, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { extractVehicleNumberFromDetails, useUrlState, withRoles } from "utils";
import ViewNotesContainer from "./view";
import { Action } from "components/buttons/action-button";
import { usePagination } from "hooks";
import { useFormik } from "formik";
import RemoveContainer from "./remove";
import ReopenContainer from "./reopen";
import SendEmailsContainer from "./send-documents";
import YearPicker from "layouts/calendar-wrapper/year-picker";
import clsx from "clsx";
import { Payment_status } from "_graphql/__generated__/globalTypes";
import SendMessageContainer from "pages/place-it-offers/message-thread";

type Props = {};


const CreateClosingPage: FC<Props> = () => {
  const [modal, setModal] = useUrlState("modal");
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { offset, limit, setPage, search } = usePagination();

  const form = useFormik<any>({
    initialValues: {
      insurer: "",
      payment_status: "",
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 10,
      search: (search || "").toString(),
      args: {
        status: ["CLOSED"],
        insurer_id: form.values.insurer || "",
        year: searchParams.year?.toString() || "",
        payment_status: form.values.payment_status ? [form.values.payment_status] : [],
        claim_status: [],
      },
    }),
    [
      form.values.insurer,
      form.values.payment_status,
      searchParams.page,
      searchParams.pageSize,
      searchParams.year,
      search,
    ]
  );

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const expandItem = (id: string) => {
    navigate({
      to: `./${id}/credit-notes`,
    });
  };

  const { offers, loading, refetch, count } = useOffers(filter);

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader
        renderActions={() => (
          <>
            <YearPicker />
          </>
        )}
      />
      {/* {JSON.stringify(form.values.insurer, null, 2)} */}
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"offers"}
            hasSearch
            loading={loading}
            columns={[
              {
                name: "Policy #",
                accessor: "offer_detail.policy_number",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col items-start flex-wrap break-all break-words">
                    <span className="font-bold text-xs overflow-hidden text-ellipsis ">
                      {value.offer_detail?.policy_number || "N/A"}
                    </span>
                    <div className={clsx("p-0.5 rounded-full", {
                      " text-green-600": value.offer_from === "INTERNAL",
                      " text-yellow-600": value.offer_from !== "INTERNAL",
                    }, "text-xs font-medium")}>
                      <span>{value.offer_from}</span>
                    </div>
                  </div>
                ),
              },
              {
                name: "Insurance Company",
                accessor: "insurer.insurer_company_name",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value.insurer?.insurer_company_name || "N/A"}
                    </span>
                    <span className="font-light">
                      {value.insurer?.insurer_company_email || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Insured",
                accessor: "offer_detail.insured_by",
                render: (value: Offers_rows) => {
                  const details = JSON.parse(
                    value?.offer_detail?.offer_details || "[]"
                  );
                  return (
                    <div className="flex flex-col text-xs ">
                      <span className="font-medium">
                        {value.offer_detail?.insured_by || "N/A"}
                      </span>
                      <span className="font-light">
                        {[
                          "Motor Comprehensive Fleet",
                          "Motor Comprehensive",
                          "Motor Comprehensive (Automobile Fac Facility)",
                        ].includes(value?.classofbusiness?.business_name || "")
                          ? extractVehicleNumberFromDetails(details)
                          : ""}
                      </span>
                    </div>
                  );
                },
              },
              {
                name: "Class Of Business",
                accessor: "classofbusiness.business_name",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value.classofbusiness?.business_name || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Sum Insured",
                accessor: "sum_insured",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value?.offer_detail?.currency}{" "}
                      {numeral(value.sum_insured).format("0,0 ") || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Premium",
                accessor: "premium",
                render: (value: Offers_rows) => (
                  <div className="flex flex-col text-xs">
                    <span className="font-medium">
                      {value?.offer_detail?.currency}{" "}
                      {numeral(value.premium).format("0,0 ") || "N/A"}
                    </span>
                  </div>
                ),
              },
              {
                name: "Offer Date",
                accessor: "created_at",
                render: (value: Offers_rows) =>
                  new Date(value.created_at).toLocaleDateString(),
              },
              {
                name: "Expiry Status",
                accessor: "expiry_status",
                render: (value: Offers_rows) =>
                  <div className="flex flex-col items-start">
                    <div className={clsx("p-1 rounded-lg", {
                      "bg-green-600/30 text-green-600": value.expiry_status === "ACTIVE",
                      "bg-red-50/30 text-red-600": value.expiry_status === "EXPIRED",
                    }, "text-xs font-medium")}>
                      <span>{value.expiry_status}</span>
                    </div>
                  </div>
              },
              {
                name: "Payment Status",
                accessor: "payment_status",
                render: (value: Offers_rows) => (
                  <div className="flex">
                    <div className={clsx("p-1 rounded-lg", {
                      "bg-green-600/30 text-green-600": value.payment_status === "PAID",
                      "bg-red-300/30 text-red-600": value.payment_status === "UNPAID",
                      "bg-sky-600/30 text-sky-600": value.payment_status === "PARTPAYMENT",
                    }, "text-xs font-medium")}>
                      <span>{value.payment_status}</span>
                    </div>
                  </div>
                )
              },
              {
                name: "Created By",
                accessor: "employee.employee_first_name",
                render: (value: Offers_rows) => (
                  <div className="flex flex-row leading-0">
                    <span>
                      {value?.employee?.employee_first_name}{" "}
                      {value?.employee?.employee_last_name}
                    </span>
                  </div>
                ),
              },

              {
                name: "Actions",
                accessor: "actions",
                render: (value: Offers_rows) => (
                  <>
                    <ActionButton
                      action="view"
                      tooltip="Debit & Cover Documents"
                      onClick={dispatchAction(value.offer_id + ":" + value?.offer_from + ":" + value?.place_it_id, "view")}
                    />
                    {value.offer_from !== "INTERNAL" && (
                      <ActionButton
                        action="chat"
                        tooltip="View Transactional Thread"
                        onClick={dispatchAction(value.place_it_id || "", "chat")}
                      />
                    )}
                    <ActionButton
                      action="send"
                      tooltip="Send Notes"
                      onClick={dispatchAction(value?.offer_id, "send")}
                    />
                    <ActionButton
                      action="goto"
                      tooltip="View Credit Notes"
                      onClick={() => expandItem(value?.offer_id)}
                    />
                    <ActionButton
                      action="reject"
                      tooltip="Reopen Offer"
                      onClick={dispatchAction(value?.offer_id, "reject")}
                    />
                    {withRoles(["CEO", "System Administrator", "Senior Broking Officer", "Finance Officer"])(
                      <ActionButton
                        action="remove"
                        tooltip="Delete Offer"
                        onClick={dispatchAction(value?.offer_id, "remove")}
                      />
                    )}

                  </>
                ),
              },
            ]}
            data={offers}
            refetch={refetch}
            renderHeaderItems={() => (
              <div className="flex items-center space-x-2">
                <RegularInsurerPicker
                  placeholder="Filter by Cedant"
                  id={"insurer"}
                  rawId
                  label={""}
                  {...form}
                />
                <RegularSelectInput
                  id="payment_status"
                  label="Payment Status"
                  hideLabel
                  options={[
                    {
                      label: "Paid",
                      value: Payment_status.PAID,
                    },
                    {
                      label: "Partpayment",
                      value: Payment_status.PARTPAYMENT,
                    },
                    {
                      label: "Unpaid",
                      value: Payment_status.UNPAID,
                    },
                  ]}
                  {...form}
                />


              </div>
            )}
            pagination={() => (
              <Paginator
                offset={offset}
                limit={limit}
                totalItems={count || 0}
                currentSize={offers.length}
                setOffset={setPage}
              />
            )}
          />
        </div>
      </div>

      {searchParams.id && (
        <>
          <ViewNotesContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <RemoveContainer
            open={modal === "remove"}
            setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
          />
          <ReopenContainer
            open={modal === "reject"}
            setOpen={(val: boolean) => setModal(val ? "reject" : undefined)}
          />
          <SendEmailsContainer
            open={modal === "send"}
            setOpen={(val: boolean) => setModal(val ? "send" : undefined)}
          />
        </>
      )}
      {searchParams?.id && modal === "chat" && (
        <SendMessageContainer
          open={modal === "chat"}
          setOpen={(val: boolean) => setModal(val ? "chat" : undefined)}
        />
      )}
    </main>
  );
};

export default CreateClosingPage;
