import { Broker_broker } from '_graphql/queries/__generated__/Broker'
import { GetAutoFacParticipant_getAutoFacParticipant } from '_graphql/queries/__generated__/GetAutoFacParticipant'
import { Avatar } from 'components/core'
import _ from 'lodash'
import { FC } from 'react'

type Props = {
    particpant: GetAutoFacParticipant_getAutoFacParticipant | null | undefined
}

const AutoFacparticipantView: FC<Props> = ({ particpant }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className='text-xs font-light'>Company Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className='row-span-2 flex flex-col space-y-3 items-center justify-center'>
                        <Avatar
                            alt={particpant?.reinsurer?.re_company_name || "N A"}
                            size="xl"
                        />
                    </div>
                    <div className='col-span-2'>
                        <span className="block text-sm font-light text-gray-700">
                            Company Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {particpant?.reinsurer?.re_company_name || "N/A"}
                        </div>
                    </div>
                    <div className=''>
                        <span className="block text-sm font-light text-gray-700">
                            Participating Percentage
                        </span>
                        <div className="mt-1 block w-full sm:text-sm text-green-500">
                            {particpant?.share_percentage || "N/A"} %
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Contact Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Email Address
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {particpant?.reinsurer?.re_company_email || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Website
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {particpant?.reinsurer?.re_company_website || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Address Information</span>
                <div className='grid grid-cols-4 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            County
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {particpant?.reinsurer?.reinsurer_address?.country || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Region
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {particpant?.reinsurer?.reinsurer_address?.region || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block col-span-3 text-sm font-light text-gray-700">
                            Street
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {particpant?.reinsurer?.reinsurer_address?.street || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block col-span-3 text-sm font-light text-gray-700">
                            City
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {particpant?.reinsurer?.reinsurer_address?.suburb || "N/A"}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AutoFacparticipantView