import { gql } from "@apollo/client";


export const getAutoFacConfig = gql`
query GetAutoFacConfig($limit: Int!, $offset: Int!) {
  getAutoFacConfigCount
  getAutoFacConfig(limit: $limit, offset: $offset) {
    name
    employee {
      employee_first_name
      employee_last_name
      employee_email
      emp_abbrv
    }
    auto_fac_config_id
  }
}
`


export const getAutoFacParticipants = gql`
query GetAutoFacParticipants(
  $limit: Int!
  $offset: Int!
  $autoFacConfigId: Int!
) {
  getAutoFacParticipants(
    limit: $limit
    offset: $offset
    auto_fac_config_id: $autoFacConfigId
  ) {
    share_percentage
    reinsurer {
      re_company_email
      re_abbrv
      re_company_name
      re_company_website
      reinsurer_id
    }
    employee {
      emp_abbrv
      employee_email
      employee_first_name
      employee_last_name
    }
    auto_fac_participant_id
  }
}
`

export const getAutoFacParticipant = gql`
query GetAutoFacParticipant($autoFacParticipantId: ID!) {
  getAutoFacParticipant(auto_fac_participant_id: $autoFacParticipantId) {
    share_percentage
    auto_fac_participant_id
    reinsurer {
      reinsurer_id
      re_company_name
      re_company_email
      re_company_website
      re_abbrv
      reinsurer_address {
        reinsurer_address_id
        street
        suburb
        region
        country
      }
    }
    auto_fac_config {
      auto_fac_config_id
      name
    }
    auto_fac_deduction {
      brokerage
      commission
      wht
      nic
      overrider
    }
  }
}
`

export const getAutoFacConfiguration = gql`
query GetAutoFacConfiguration($autoFacConfigurationId: ID!) {
  getAutoFacConfiguration(auto_fac_configuration_id: $autoFacConfigurationId) {
    name
    employee {
      employee_id
      employee_first_name
      employee_last_name
      employee_phonenumber
      employee_email
      emp_abbrv
    }
    auto_fac_participants {
      share_percentage
      reinsurer {
        reinsurer_id
        re_company_name
        re_company_email
        re_company_website
        re_abbrv
      }
      employee {
        employee_first_name
        employee_last_name
      }
    }
  }
}
  `