import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { PopUp } from "components";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useAppSearch, useCurrentUser } from "_graphql/cache/auth";
import toast from "react-hot-toast";
import { rejectUnderwriterOffer } from "_graphql/mutation/underwriter.place-it";
import { DeclineOffer, DeclineOfferVariables } from "_graphql/mutation/__generated__/DeclineOffer";
import { placeItClient } from "_graphql";


export default function RejectContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useAppSearch()
    const currentUser = useCurrentUser()

    const navigate = useNavigate<LocationGenerics>();
    const [execute, { loading }] = useMutation<
        DeclineOffer,
        DeclineOfferVariables
    >(rejectUnderwriterOffer, {
        client: placeItClient
    });

    const form = useFormik<any>({
        initialValues: {
            note: ""
        },
        onSubmit: async (values, helpers) => {
            execute({
                variables: {
                    employeeId: currentUser?.employee?.employee_id || "",
                    offerId: searchParams?.id || "",
                    comment: values.note,
                    employeeName: `${currentUser?.employee?.employee_first_name} ${currentUser?.employee?.employee_last_name}`
                }
            }).then(({ data }) => {
                if (data?.declineOffer?.message) {
                    helpers.resetForm();
                    toast.success(data?.declineOffer?.message || "Underwriter offer declined successfully");
                    navigate({
                        search(prev) {
                            return {
                                ...prev,
                                id: undefined,
                                modal: undefined,
                            };
                        },
                    });
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Could not decline underwriter offer");
            });
        },
        onReset: () => {
            setOpen(false);
            navigate({
                search(prev) {
                    return {
                        ...prev,
                        id: undefined,
                        modal: undefined,
                    };
                },
            });
        },
    });



    const handleSubmit = () => {
        form.submitForm();
    }


    return (
        <PopUp
            open={open}
            setOpen={setOpen}
            title="Decline Offer"
            cancelText="Cancel"
            description="This action would decline the offer"
            form={form}
            reason="Please provide a reason for declining the offer"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={handleSubmit}
                    >
                        {loading ? "Declining Offer..." : "Decline Offer"}
                    </button>
                </>
            )}
        />
    );
}



