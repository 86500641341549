import { Modal, PDFRenderer } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useInsurer } from "hooks/data/use-insurers";
import config from "config";
import _ from "lodash";

export default function ViewTreatySlipContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { params } = useMatch<LocationGenerics>();
    // const { loading } = useInsurer(searchParams?.id || "");

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            // loading={loading}
            hidePadding
            size="6xl"
            title={_.startCase(searchParams.id)}
            description={`Details of ${_.startCase(searchParams.id)} are shown below`}
        >
            <PDFRenderer
                url={`${config.asset.uri}treaty_placing_cover_note/${btoa(
                    JSON.stringify({
                        treaty_id: params?.treaty,
                        doc_type: _.snakeCase(searchParams.id),
                    })
                )}`}
            />
        </Modal>
    );
}
