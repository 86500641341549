import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { PercentageForm } from "components/forms";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import { useAutoFacConfigurationParticipant } from "hooks/data/use-aut-fac";
import { updateAutoFacParticipantShare } from "_graphql/mutation/auto-fac";
import { UpdateAutoFacParticipantShare, UpdateAutoFacParticipantShareVariables } from "_graphql/mutation/__generated__/UpdateAutoFacParticipantShare";

export const valiadationSchema = Yup.object().shape({
    expected: Yup.number().min(0).required(),
    percentage: Yup.number().min(0, "Percentage must be greater than 0").max(Yup.ref("expected"), "Percentage must be less than or same percentage left")
        .typeError("Must be a number")
        .required("Percentage is required"),
});

export default function AddParticipantsPercentageContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const { params } = useMatch<LocationGenerics>();
    const searchParams = useSearch<LocationGenerics>();
    const { participant, loading: fetching } = useAutoFacConfigurationParticipant({
        autoFacParticipantId: searchParams.id || "",
    });

    const [create, { loading }] = useMutation<
        UpdateAutoFacParticipantShare,
        UpdateAutoFacParticipantShareVariables
    >(updateAutoFacParticipantShare, {
        refetchQueries: ["GetAutoFacParticipants"],
    });

    const form = useFormik<any>({
        initialValues: {
            percentage: 0,
            expected: 100
        },
        validationSchema: valiadationSchema,
        onSubmit: async (variables) => {
            await create({
                variables: {
                    autoFacParticipantId: searchParams.id || "",
                    // offer_participant_id: searchParams.id || "",
                    share: variables.percentage,
                },
            }).then(({ data }) => {
                toast.success("Percentage updated Successfully");
                refetch?.();
                form.resetForm();
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (participant) {
            form.setFieldValue(
                "percentage",
                participant?.share_percentage || 0
            );
            // const remainingPercentages =
            form.setFieldValue("expected", parseFloat("100"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participant, searchParams.reinsurer]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={"Add Percentage to " + participant?.reinsurer?.re_company_name}
            loading={fetching}
            description="Add percentage for this ReInsurer"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Saving changes..." : "Save changes"}
                    </button>
                </>
            )}
        >
            <PercentageForm form={form} />
        </Modal>
    );
}
