import { FC } from "react";
import _ from "lodash";
import { classNames, wrapImage } from "utils";
import clsx from "clsx";

interface AvatarProps {
  src?: string;
  alt: string;
  size?: "sm" | "md" | "lg" | "xl" | "2xl";
  disabled?: boolean;
  color?: "gray" | "red" | "yellow" | "green" | "blue" | "indigo" | "purple" | "pink";
}

const Avatar: FC<AvatarProps> = ({ src, alt, size = "sm", disabled, color }) => {

  const textColor = color ? `text-${color}-500` : "text-gray-500";

  if (src?.length) {
    return !disabled ? (
      wrapImage(
        <img
          className={classNames(
            size === "sm" ? "h-10 w-10 " : "",
            size === "md" ? "h-14 w-14 " : "",
            size === "lg" ? "h-20 w-20 " : "",
            size === "xl" ? "h-28 w-28 " : "",
            size === "2xl" ? "h-36 w-36" : "",
            "rounded-full object-cover z-0"
          )}
          src={src}
          alt={alt}
        />
      )
    ) : (
      <img
        className={classNames(
          size === "sm" ? "h-10 w-10 " : "",
          size === "md" ? "h-14 w-14 " : "",
          size === "lg" ? "h-20 w-20 " : "",
          size === "xl" ? "h-28 w-28 " : "",
          size === "2xl" ? "h-36 w-36" : "",
          "rounded-full object-cover z-0"
        )}
        src={src}
        alt={alt}
      />
    );
  }
  return (
    <div
      className={clsx(
        size === "sm" ? "h-10 w-10 " : "",
        size === "md" ? "h-14 w-14 " : "",
        size === "lg" ? "h-20 w-20 " : "",
        size === "xl" ? "h-28 w-28 " : "",
        "rounded-full flex items-center justify-center bg-gray-200",
        {
          "bg-gray-200": !color,
          "bg-red-200": color === "red",
          "bg-yellow-200": color === "yellow",
          "bg-green-200": color === "green",
          "bg-blue-200": color === "blue",
          "bg-indigo-200": color === "indigo",
          "bg-purple-200": color === "purple",
          "bg-pink-200": color === "pink",

        }
      )}
    >
      <span className={clsx("text-sm", textColor)}>
        {_.chain(alt)
          .split(" ")
          .slice(0, 2)
          .map((s) => s.charAt(0))
          .join("")
          .upperCase()
          .value()}
      </span>
    </div>
  );
};

export default Avatar;
