import clsx from 'clsx'
import { FC, useState } from 'react';

type Props = {
    status: "success" | "error" | "warning" | "neutral" | "sky" | "orange";
    tooltip?: any;
    label: string;
    showToolTip?: boolean
}

const statusColors = {
    success: "bg-green-600/30 text-green-600",
    error: "bg-red-600/30 text-red-600",
    warning: "bg-yellow-600/30 text-yellow-600",
    neutral: "bg-gray-50 text-gray-600",
    sky: "bg-sky-50 text-sky-600",
    orange: "bg-orange-50 text-orange-600",
}

const Badge: FC<Props> = ({ status, tooltip, label, showToolTip = false }) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    return (
        <div className="flex relative"
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
        >
            <div className={clsx("p-0.5 rounded-full", statusColors[status], "text-xs px-5 font-medium")}>
                <span>{label}</span>
            </div>
            {tooltipVisible && showToolTip && (
                <div className="absolute top-full mt-2 left-1/2 -translate-x-1/2 p-2.5 bg-gray-900 border border-gray-700 rounded-lg shadow-xl z-50 w-48">
                    <div className="absolute -top-2 left-1/2 -translate-x-1/2 w-3 h-3 bg-gray-900 border-t border-l border-gray-700 transform rotate-45" />
                    <p className="text-xs text-gray-300 relative z-10" dangerouslySetInnerHTML={{ __html: tooltip || "" }}></p>
                </div>
            )}
        </div>
    )
}

export default Badge