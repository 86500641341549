import { LanguagePicker, Modal, PDFRenderer } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import config from "config";
import { useFormik } from "formik";
import { useState } from "react";
import { classNames, wrapClick } from "utils";

const tabs = [
  {
    name: "Placing Slip",
    href: "PLACING_SLIP",
  },
  {
    name: "Supporting Documents",
    href: "SUPPORTING_DOCUMENTS",
  },
];

export default function ViewOfferSlipContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const [orderStatus, setOrderStatus] = useState("PLACING_SLIP");
  const searchParams = useSearch<LocationGenerics>();

  const [id, type, place_it_id] = searchParams?.id?.split(":") || [];
  const form = useFormik({
    initialValues: {
      lang: "en"
    },
    onSubmit(values, formikHelpers) {

    },
  })


  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="6xl"
      title="Placing Slip"
      description="Details of placing slip are shown below"
      hideActions
    >
      {type === "PLACEIT" && (
        <div className="block">
          <div className="border-b border-shade-500 bg-card-500 px-6">
            <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
              {tabs.map((_orderStatus) => (
                <button
                  key={_orderStatus.name}
                  onClick={wrapClick(() => setOrderStatus(_orderStatus.href))}
                  className={classNames(
                    orderStatus === _orderStatus.href
                      ? "border-gray-200 text-gray-300"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={
                    orderStatus === _orderStatus.href ? "page" : undefined
                  }
                >
                  {_orderStatus.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      )}
      {orderStatus === "PLACING_SLIP" && (
        <div className="mb-3 p-3">
          {/* {searchParams?.id} */}
          <LanguagePicker id={"lang"} labelHidden {...form} />
        </div>
      )}
      <div className="p-3">
        <PDFRenderer
          url={orderStatus === "PLACING_SLIP" ? `${config.asset.uri}generate_placing_slip/${btoa(
            JSON.stringify({
              offer_id: id,
              lang: form.values.lang,
            })
          )}` : `${config.asset.placeItUri}/generate-broker-supporting-docs/${place_it_id}`}
        />
      </div>
    </Modal>
  );
}
