import packageJson from "./../../package.json";
// import firebaseConfig from "./firebase";

if (process.env.NODE_ENV === "production") {
  const variables = [
    "REACT_APP_APOLLO_URI",
    // "REACT_APP_SENTRY_DSN"
  ];
  for (let variable of variables) {
    if (!process.env[variable]) {
      throw new Error(`Kindly Provide Variable ${variable} In Env`);
    }
  }
}

interface Config {
  env: "production" | "staging" | "sandbox" | "test" | "development";
  name: string;
  asset: {
    uri: string;
    placeItUri: string;
  };
  apollo: {
    uri: string;
    placeItUri: string;
    name: string;
    version: string;
  };
  sentry: {
    dsn: string;
    env: "production" | "staging" | "sandbox" | "test";
  };
  cookies: {
    domain: string;
    secure: boolean;
    sameSite: "strict" | "Strict" | "lax" | "Lax" | "none" | "None";
  };
  constants: {
    page: number;
    pageSize: number;
    dateFormat: string;
  };
  pusher: {
    key: string;
    cluster: string;
    channel: string;
    event: string;
    //     export const PUSHER_KEY = "6732a1bd9862dfe49492";
    // export const PUSHER_CLUSTER = "eu";
    // export const CHANNEL = "LgzULSz64aed7WJ7YwDq3qmHAeX786dZNZ3Ko4Bisf0";
    // export const EVENT = "LgzULSz64aed7WJ7YwDq3qmHAeX786dZNZ3Ko4Bisf0-event";
  }
}

const config: Config = {
  env: (process.env.NODE_ENV as any) ?? "development",

  name: packageJson.name,
  asset: {
    uri: process.env.REACT_APP_ASSET_URI ?? "http://127.0.0.1:4000/api/assets",
    placeItUri: process.env.REACT_APP_PLACE_IT_ASSET_URI ?? "http://127.0.0.1:4000/api/assets",
  },
  apollo: {
    placeItUri: process.env.REACT_APP_PLACE_IT_URI ?? "http://127.0.0.1:4000/graphql",
    uri: process.env.REACT_APP_APOLLO_URI ?? "http://127.0.0.1:4000/graphql",
    name: packageJson.name,
    version: packageJson.version,
  },
  sentry: {
    dsn:
      process.env.REACT_APP_SENTRY_DSN ??
      "https://examplePublicKey@o0.ingest.sentry.io/0",
    env: process.env.REACT_APP_SENTRY_ENV as any,
  },
  cookies: {
    domain: window.location.hostname?.split(".")?.slice(1)?.join("."),
    secure: true,
    sameSite: "strict",
  },
  constants: {
    page: 1,
    pageSize: 10,
    dateFormat: "",
  },
  pusher: {
    channel: process.env.REACT_APP_PUSHER_CHANNEL ?? "LgzULSz64aed7WJ7YwDq3qmHAeX786dZNZ3Ko4Bisf0",
    cluster: process.env.REACT_APP_PUSHER_CLUSTER ?? "eu",
    key: process.env.REACT_APP_PUSHER_KEY ?? "6732a1bd9862dfe49492",
    event: process.env.REACT_APP_PUSHER_EVENT ?? "LgzULSz64aed7WJ7YwDq3qmHAeX786dZNZ3Ko4Bisf0-event",
  }
};

export default config;
