import { useAppNavigate, useAppSearch } from '_graphql/cache/auth';
import { GetUnderwriters_underwriters_data } from '_graphql/queries/__generated__/GetUnderwriters';
import { ActionButton, Badge, OfficeHeader, Paginator, TableComponent } from 'components';
import { useUnderWriters } from 'hooks/data/useUnderWriters';
import { FC } from 'react';
import ApproveContainer from './approve';
import { Action } from 'components/buttons/action-button';
import { ApprovalStatus } from '_graphql/__generated__/globalTypes-place-it';
import _ from 'lodash';
import { usePagination } from 'hooks';
import moment from 'moment';

type Props = {}

const UnderwritersPage: FC<Props> = () => {
    const searchParams = useAppSearch();
    const navigate = useAppNavigate();
    const { offset, limit, setPage, search } = usePagination()
    //  calculate the page number using the offset and limit
    const page = Math.floor(offset / limit) + 1;
    const { underwriters, loading, refetch, length } = useUnderWriters({
        q: "ACTIVE",
        p: "ACTIVE",
        filters: {
            page: page,
            limit: limit,
            search: search || "",
        }
    });

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        title={"Underwriters"}
                        data={underwriters}
                        columns={[
                            {
                                accessor: "assoc_last_name",
                                name: "Associate | Insurance Company",
                                render: (row: GetUnderwriters_underwriters_data) => (
                                    <div className="flex flex-col items-start">
                                        <div className="text-sm font-medium">{_.startCase(row.assoc_last_name)} {row.assoc_first_name}</div>
                                        <div className="text-sm font-light">{row.insurer?.insurer_company_name}</div>
                                    </div>
                                )
                            },
                            {
                                accessor: "assoc_primary_phonenumber",
                                name: "Primary Phone | Email",
                                render: (row: GetUnderwriters_underwriters_data) => (
                                    <div className="flex flex-col items-start">
                                        <div className="text-sm font-medium">{row.assoc_primary_phonenumber}</div>
                                        <div className="text-sm font-light">{row.assoc_email}</div>
                                    </div>
                                )
                            },
                            {
                                accessor: "position",
                                name: "Position",
                                render: (row: GetUnderwriters_underwriters_data) => row.position
                            },
                            {
                                accessor: "account_state_broker_approval",
                                name: "Broker Approval Status",
                                render: (row: GetUnderwriters_underwriters_data) => (
                                    <div className='flex'>
                                        <Badge status={
                                            row.account_state_broker_approval === ApprovalStatus.ACTIVE ? "success" :
                                                row.account_state_broker_approval === ApprovalStatus.DEACTIVATE ? "error" :
                                                    row.account_state_broker_approval === ApprovalStatus.PENDING ? "warning" :
                                                        row.account_state_broker_approval === ApprovalStatus.PROBATION ? "neutral" :
                                                            "neutral"
                                        } tooltip={`<div className="prose">
                                        <p>${row?.kyc_approvals?.approved_by} changed to this status on ${moment(row?.kyc_approvals?.approval_date).format("DD/MM/YYYY")}</p>
                                        <p>Reason: ${row?.kyc_approvals?.comment}</p>
                                        </div>`}
                                            showToolTip={["PROBATION", "DEACTIVATE"].includes(row.account_state_broker_approval || "")}
                                            label={row.account_state_broker_approval || "N/A"} />
                                    </div>
                                )
                            },
                            {
                                accessor: "account_state_manager_approval",
                                name: "Mgr Approval Status",
                                render: (row: GetUnderwriters_underwriters_data) => (
                                    <Badge status={
                                        row.account_state_manager_approval === ApprovalStatus.ACTIVE ? "success" :
                                            row.account_state_manager_approval === ApprovalStatus.DEACTIVATE ? "error" :
                                                row.account_state_manager_approval === ApprovalStatus.PENDING ? "warning" :
                                                    row.account_state_manager_approval === ApprovalStatus.PROBATION ? "neutral" :
                                                        "neutral"
                                    } tooltip={`<div className="prose">
                                        <p>${row?.kyc_approvals?.approved_by} changed to this status on ${moment(row?.kyc_approvals?.approval_date).format("DD/MM/YYYY")}</p>
                                        <p>Reason: ${row?.kyc_approvals?.comment}</p>
                                        </div>`}
                                        showToolTip={["PROBATION", "DEACTIVATE"].includes(row.account_state_manager_approval || "")}
                                        label={row.account_state_manager_approval || "N/A"} />
                                )
                            },
                            {
                                accessor: "assoc_phone",
                                name: "",
                                render: (row: GetUnderwriters_underwriters_data) => (
                                    <div>
                                        {row.position !== "Manager" && <ActionButton action='approve' tooltip='Broker Approval' onClick={dispatchAction(row.id, "approve")} />}
                                    </div>
                                )
                            }
                        ]}
                        refetch={refetch}
                        loading={loading}
                        hasSearch
                        pagination={() => <Paginator offset={offset} limit={limit} totalItems={length} currentSize={underwriters.length} setOffset={setPage} />}
                    />
                </div>
            </div>
            {
                searchParams?.id && searchParams?.modal === "approve" && (
                    <ApproveContainer
                        open={searchParams?.modal === "approve"}
                        setOpen={(value) => navigate({
                            search(prev) {
                                return {
                                    ...prev,
                                    modal: value ? "approve" : undefined,
                                    id: value ? searchParams?.id : undefined
                                }
                            },
                        })}
                    />
                )
            }
        </main >
    )
}

export default UnderwritersPage