/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApprovalStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATE = "DEACTIVATE",
  PENDING = "PENDING",
  PROBATION = "PROBATION",
}

export enum BrokerApprovalState {
  ACTIVE = "ACTIVE",
  DEACTIVATE = "DEACTIVATE",
  PROBATION = "PROBATION",
}

export enum Claim_status {
  CLAIMED = "CLAIMED",
  UNCLAIMED = "UNCLAIMED",
}

export enum OfferThreadNotificationStatus {
  READ = "READ",
  UNREAD = "UNREAD",
}

export enum Offer_status {
  ACCEPTED = "ACCEPTED",
  CLOSED = "CLOSED",
  INITIATED = "INITIATED",
  OPEN = "OPEN",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum Payment_status {
  PAID = "PAID",
  PARTPAYMENT = "PARTPAYMENT",
  UNPAID = "UNPAID",
}

export enum TransactionStatus {
  APPROVED = "APPROVED",
  INITIATED = "INITIATED",
  MODIFY = "MODIFY",
  REJECTED = "REJECTED",
}

export interface OfferFilters {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
  class_of_business?: string | null;
  year?: string | null;
  insurer?: string | null;
  broker?: string | null;
  offer_status?: Offer_status | null;
  payment_status?: Payment_status | null;
}

export interface UnderwriterFilters {
  page?: number | null;
  limit?: number | null;
  search?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
